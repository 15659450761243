import { Icon } from "@iconify/react";
import React from "react";

export default function Contact() {
  return (
    <section style={{ marginTop: "280px" }}>
      <div className="container">
        <div className="contact-wraper">
          <div className="row">
            <div className="col-lg-6 mb-30">
              <h2 className="mb-5">Contact Us</h2>
              <form className="form-wraper contact-form ajax-form" action="">
                <div className="ajax-message" />
                <div className="row">
                  <div className="form-group col-md-6">
                    <input
                      name="last_name"
                      type="text"
                      required=""
                      className="form-control"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      name="name"
                      type="text"
                      required=""
                      className="form-control"
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      name="email"
                      type="email"
                      required=""
                      className="form-control"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      name="phone"
                      type="phone"
                      required=""
                      className="form-control"
                      placeholder="Phone Numbers"
                    />
                  </div>
                  <div className="form-group col-12">
                    <input
                      name="subject"
                      type="text"
                      required=""
                      className="form-control"
                      placeholder="Subject"
                    />
                  </div>

                  <div className="form-group col-md-12">
                    <textarea
                      name="message"
                      required=""
                      className="form-control"
                      placeholder="Type Message"
                      defaultValue={""}
                    />
                  </div>

                  <div className="col-lg-12">
                    <button
                      name="submit"
                      type="submit"
                      value="Submit"
                      className="btn w-100 btn-secondary btn-lg"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-6 mb-30">
              <div
                className="contact-info ovpr-dark"
                style={{
                  backgroundImage: "url(/dr.jpg)",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="info-inner">
                  <h4 className="title mb-30">Contact Informations</h4>
                  <hr />
                  <ul className="text-start">
                    <li>
                      <a
                        className="text-white text-hover-dark"
                        href="https://maps.app.goo.gl/RRCjgphbjc2i336g6"
                        target="_blank"
                      >
                        <Icon icon="zondicons:location" /> 2090 Route 27 Edison,
                        NJ 08817
                      </a>
                    </li>
                    <li>
                      <Icon icon="bxs:phone" />{" "}
                      <a
                        className="text-white text-hover-dark"
                        href="tel:732-662-5650"
                      >
                        732-662-5650
                      </a>
                    </li>
                    <li>
                      {" "}
                      <Icon icon="ri:time-line" /> Mon-Fri 8am - 8pm <br />
                      <span className="ms-3 ps-1"></span> Sat-Sun 8am - 6pm
                    </li>
                    <hr />
                    <li className="mt-2">
                      <a
                        className="text-white text-hover-dark"
                        href="https://maps.app.goo.gl/rkvwhM6tYj5NTX9P9"
                        target="_blank"
                      >
                        <Icon icon="zondicons:location" />{" "}
                        <small>22690 Woodbridge Ave Edison, NJ 08837</small>
                      </a>
                    </li>
                    <li>
                      <Icon icon="bxs:phone" />{" "}
                      <a
                        className="text-white text-hover-dark"
                        href="tel:732-662-9145"
                      >
                        732-662-9145
                      </a>
                    </li>
                    <li>
                      {" "}
                      <Icon icon="ri:time-line" /> Mon-Fri 9am-5pm <br />
                      <span className="ms-3 ps-1"></span> Sat-Sun Closed
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-5">
<h1 className="my-4">Our Locations</h1>
            <div className="col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3032.71064934961!2d-74.3864162!3d40.525886199999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3c819bc2c40e5%3A0x29e83366b90edee5!2s2090%20NJ-27%2C%20Edison%2C%20NJ%2008817%2C%20USA!5e0!3m2!1sen!2s!4v1695408944190!5m2!1sen!2s"
                className="w-100"
                height="350"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="col-md-6">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3033.3646520860875!2d-74.3638144!3d40.511430499999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3c835f6d84f0b%3A0xb721e383f974d2b8!2s2690%20Woodbridge%20Ave%2C%20Edison%2C%20NJ%2008837%2C%20USA!5e0!3m2!1sen!2s!4v1695409235551!5m2!1sen!2s" className="w-100" height="350"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
