import { Icon } from "@iconify/react";
import React from "react";

export default function Services() {
  return (
    <section id="services" className="section-area section-sp1 service-wraper ps-0">
      <div className="row align-items-center ">
        <div className="container ms-5 heading-bx text-start">
          {/* <h6 className="title-ext text-secondary">Working Process</h6> */}
          <h2 className="title">Popular Services</h2>
        </div>
        <div className="col-12 mb-15">
          <div className="swiper-container service-slide">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="feature-container feature-bx2 feature1">
                  <div className="feature-box-xl mb-30">
                    <span className="icon-cell">
                      <Icon className="text-primary"
                        icon="ic:sharp-personal-injury"
                        width="70"
                        height="70"
                      />
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">illness & Injury Care</h3>
                    <p>Walk in urgent care visits, no appointment needed</p>
                    <a href="#" className="btn btn-primary light">
                      View More
                    </a>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="feature-container feature-bx2 feature2">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">

                      <Icon className="text-primary"
                        icon="mingcute:safe-shield-fill"
                        width="70"
                        height="70"
                      />
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">Preventative Care</h3>
                    <p>Preventative Care: Prioritizing Health.</p>
                    <a href="#" className="btn btn-primary light">
                      View More
                    </a>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="feature-container feature-bx2 feature3">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <Icon className="text-primary" icon="entypo:lab-flask" width="70" height="70" />
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">Lab Service</h3>
                    <p>Lab tech always on staff and daily pickups</p>
                    <a href="#" className="btn btn-primary light">
                      View More
                    </a>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="feature-container feature-bx2 feature1">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <Icon className="text-primary" icon="cil:child" width="70" height="70" />
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">Pediatrics Welcome</h3>
                    <p>Helping every member of your family</p>
                    <a href="#" className="btn btn-primary light">
                      View More
                    </a>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="feature-container feature-bx2 feature2">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                    <Icon className="text-primary"
                        icon="material-symbols:vaccines-rounded"
                        width="70"
                        height="70"
                      />
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">Vaccines</h3>
                    <p>Adminstering routine vaccines as well as travel</p>
                    <a href="#" className="btn btn-primary light">
                      View More
                    </a>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="feature-container feature-bx2 feature2">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <Icon className="text-primary" icon="maki:doctor" width="70" height="70" />
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">Physical Exams</h3>
                    <p>We offer annual physicals exams and DOT testing. </p>
                    <a href="#" className="btn btn-primary light">
                      View More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className="pt-img1 animate-rotate"
        src="images/shap/line-circle-blue.png"
        alt=""
      />
      <img
        className="pt-img2 animate2"
        src="images/shap/square-dots-orange.png"
        alt=""
      />
      <img
        className="pt-img3 animate-wave"
        src="images/shap/wave-blue.png"
        alt=""
      />
      <img
        className="pt-img4 animate1"
        src="images/shap/square-rotate.png"
        alt=""
      />
    </section>
  );
}
