import React from "react";

export default function Testimonial() {
  return (
    <section id="reviews"className="section-area section-sp3 testimonial-wraper pb-5" style={{backgroundColor:"#fafaf8"}}>
      <div className="container">
        <div className="heading-bx text-center">
          <h2 className="title m-b0">Discover Our Patients' Testimonials</h2>
        </div>
        <div className="row align-items-center">
          <div className="col-md-8 mx-auto">
            <div className="swiper-container testimonial-slide">
              <div className="swiper-wrapper">
                <div className="swiper-slide" data-rel={1}>
                  <div className="testimonial-bx">
                    <div className="testimonial-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecena ssuspendisse ultrices
                        gravida.
                      </p>
                    </div>
                    <div className="client-info">
                      <h5 className="name">John Deo</h5>
                      <p>patient</p>
                    </div>
                    <div className="quote-icon">
                      <i className="fas fa-quote-left" />
                    </div>
                  </div>
                </div>
                <div className="swiper-slide" data-rel={2}>
                  <div className="testimonial-bx">
                    <div className="testimonial-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecena ssuspendisse ultrices
                        gravida.
                      </p>
                    </div>
                    <div className="client-info">
                      <h5 className="name">John Deo</h5>
                      <p>patient</p>
                    </div>
                    <div className="quote-icon">
                      <i className="fas fa-quote-left" />
                    </div>
                  </div>
                </div>
                <div className="swiper-slide" data-rel={3}>
                  <div className="testimonial-bx">
                    <div className="testimonial-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecena ssuspendisse ultrices
                        gravida.
                      </p>
                    </div>
                    <div className="client-info">
                      <h5 className="name">John Deo</h5>
                      <p>patient</p>
                    </div>
                    <div className="quote-icon">
                      <i className="fas fa-quote-left" />
                    </div>
                  </div>
                </div>
                <div className="swiper-slide" data-rel={4}>
                  <div className="testimonial-bx">
                    <div className="testimonial-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecena ssuspendisse ultrices
                        gravida.
                      </p>
                    </div>
                    <div className="client-info">
                      <h5 className="name">John Deo</h5>
                      <p>patient</p>
                    </div>
                    <div className="quote-icon">
                      <i className="fas fa-quote-left" />
                    </div>
                  </div>
                </div>
                <div className="swiper-slide" data-rel={5}>
                  <div className="testimonial-bx">
                    <div className="testimonial-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecena ssuspendisse ultrices
                        gravida.
                      </p>
                    </div>
                    <div className="client-info">
                      <h5 className="name">John Deo</h5>
                      <p>patient</p>
                    </div>
                    <div className="quote-icon">
                      <i className="fas fa-quote-left" />
                    </div>
                  </div>
                </div>
                <div className="swiper-slide" data-rel={6}>
                  <div className="testimonial-bx">
                    <div className="testimonial-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecena ssuspendisse ultrices
                        gravida.
                      </p>
                    </div>
                    <div className="client-info">
                      <h5 className="name">John Deo</h5>
                      <p>patient</p>
                    </div>
                    <div className="quote-icon">
                      <i className="fas fa-quote-left" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-button-prev test-btn-prev">
                <i className="las la-arrow-left" />
              </div>
              <div className="swiper-button-next test-btn-next">
                <i className="las la-arrow-right" />
              </div>
              <div className="swiper-pagination" />
            </div>
          </div>
        </div>
      </div>

      <img
        className="pt-img2 animate2"
        src="images/shap/square-blue.png"
        alt=""
      />
      <img
        className="pt-img3 animate3"
        src="images/shap/circle-dots.png"
        alt=""
      />
    </section>
  );
}
