import React from "react";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";

export default function Hero() {
  return (
    <div className="main-banner">
      <div className="container inner-content">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-6 col-sm-7">
            <h6 className="title-ext text-primary">
              Welcome to Urgent Care of NJ
            </h6>
            <div className="w-100 d-inline-block">
              <h1 className="d-inline-block">
                <Typewriter
                  options={{
                    strings: [
                      '<span class="text-primary">Urgent Care</span> With a Primary Physician Touch',
                    ],
                    autoStart: true,

                    loop: false,
                    delay: 100, // Typing speed (in milliseconds)
                  }}
                  // Use the `html` option to interpret HTML tags in the string
                  // Be cautious when using this option to prevent security vulnerabilities
                  // In this case, we're controlling the content, so it should be safe
                  // If the content comes from user input, consider sanitizing it
                  // See https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
                  // for more details.
                  // eslint-disable-next-line react/no-danger
                  onInit={(typewriter) => {
                    typewriter
                      .typeString('<span class="text-primary">Urgent Care</span> With a Primary Physician Touch')
                      .start();
                  }}
                />
              </h1>
            </div>
            <Link to="/contact" className="btn btn-secondary btn-lg shadow">
              Get in Touch
            </Link>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-5">
            <div className="banner-img">
              <img src="/logo.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
