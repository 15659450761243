import { Icon } from '@iconify/react';
import React, { useState, useEffect } from 'react';

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  // Show the button when the user scrolls down 300px
  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  }, []);

  // Scroll to the top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling animation
    });
  };

  return (
    <>
      {isVisible && (
        <button className="rounded-circle scroll-to-top-button" onClick={scrollToTop}>
       <Icon icon="icon-park-outline:up-two" />
        </button>
      )}
    </>
  );
}

export default ScrollToTopButton;
