import React from "react";

export default function Insurance() {
  return (
    <section style={{ marginTop: "280px" }}>
      <div className="container">
        <div className="contact-wraper">
          <h1 className="text-center">Insurance</h1>
          <p className="text-center">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Impedit,
            laboriosam! Aspernatur dolor qui recusandae quis ab assumenda
            voluptatum nam doloribus aut ducimus ex temporibus esse eveniet
            laudantium quasi minima, officiis eos quae magnam voluptatibus
            aliquam delectus? Quo cum odio dolores quos sunt. Dolor distinctio
            id veniam vero sed vitae, necessitatibus at. Esse enim saepe quaerat
            voluptates modi. Culpa ipsum ipsa dicta, odit ratione voluptatibus
            perspiciatis incidunt quis placeat, ab debitis adipisci eligendi
            officiis provident molestiae rerum temporibus at.
          </p>

          <div className="row my-5">
            <div className="col-md-8">
              <div className="accordion ttr-accordion1" id="accordionRow1">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="heading3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse3"
                      aria-expanded="false"
                      aria-controls="collapse3"
                    >
                      Insurance 1
                    </button>
                  </h2>
                  <div
                    id="collapse3"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading3"
                    data-bs-parent="#accordionRow1"
                  >
                    <div className="accordion-body text-start">
                      <p className="mb-0">Insurance 1 details</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="heading4">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse4"
                      aria-expanded="false"
                      aria-controls="collapse4"
                    >
                      Insurance 2
                    </button>
                  </h2>
                  <div
                    id="collapse4"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading4"
                    data-bs-parent="#accordionRow1"
                  >
                    <div className="accordion-body text-start">
                      <p className="mb-0">Insurance 2 details</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="heading4">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse3"
                      aria-expanded="false"
                      aria-controls="collapse4"
                    >
                      Insurance 3
                    </button>
                  </h2>
                  <div
                    id="collapse3"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading4"
                    data-bs-parent="#accordionRow1"
                  >
                    <div className="accordion-body text-start">
                      <p className="mb-0">Insurance 3 details</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="col-12 p-4 text-start"
                style={{ backgroundColor: "#fff1f1" }}
              >
                <h4>Not seeing your plan or carrier?</h4>
                <p>Call us or email us to discuss your payment options.</p>
              </div>
            </div>
          </div>
          <h1 className="text-center mt-3">Other ways to pay.</h1>
          <p>
            If you don’t have health insurance, don’t worry—you can still visit
            UCONJ. We offer competitive and affordable uninsured rates.
          </p>
          <button className="btn btn-primary rounded-pill">Pay Now</button>
        </div>
      </div>
    </section>
  );
}
