import React from "react";

export default function FAQ() {
  return (
    <section id="faq"
      className="section-sp3 my-4 pb-2"

    >
      <h2 className=" m-b0 py-4">FAQs</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="accordion ttr-accordion1" id="accordionRow1">
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading1">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse1"
                    aria-expanded="false"
                    aria-controls="collapse1"
                  >
                    Does Urgent Care of NJ give vaccinations?
                  </button>
                </h2>
                <div
                  id="collapse1"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading1"
                  data-bs-parent="#accordionRow1"
                  style={{}}
                >
                  <div className="accordion-body">
                    <p className="mb-0">
                      We do administer many vaccines, call today to see if we
                      have your vaccine in stock if not it can be ordered for
                      pickup to be administered by our staff.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading2">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse2"
                    aria-expanded="false"
                    aria-controls="collapse2"
                  >
                    Does the clinic accept pediatrics?
                  </button>
                </h2>
                <div
                  id="collapse2"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading2"
                  data-bs-parent="#accordionRow1"
                >
                  <div className="accordion-body">
                    <p className="mb-0">
                      We accept newborn and up, seeing all ages with a
                      pediatrician as our medical director.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="accordion ttr-accordion1" id="accordionRow1">
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading3">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse3"
                    aria-expanded="false"
                    aria-controls="collapse3"
                  >
                    How can I become a new patient?
                  </button>
                </h2>
                <div
                  id="collapse3"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading3"
                  data-bs-parent="#accordionRow1"
                >
                  <div className="accordion-body">
                    <p className="mb-0">
                      Walk in today, no appointment needed! If you have any
                      medical records you would like to share bring in to your
                      first appointment
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading4">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse4"
                    aria-expanded="false"
                    aria-controls="collapse4"
                  >
                    Does Urgent Care of NJ take my insurance?
                  </button>
                </h2>
                <div
                  id="collapse4"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading4"
                  data-bs-parent="#accordionRow1"
                >
                  <div className="accordion-body">
                    <p className="mb-0">
                      Urgent Care of NJ participates with most major private
                      payors including but not limited to: 1199, Aetna, BCBS,
                      Qualcare, Magnacare, United Healthcare, Oxford and more.
                      Call today to see if we take your insurance today
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
