import { Icon } from "@iconify/react";
import React from "react";

export default function Counter() {
  return (
    <section class="section-sp1 service-wraper2">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-sm-6 mb-30">
            <div class="feature-container feature-bx3">
              <Icon
                icon="raphael:lab"
                className="mb-4 ttr-title text-primary"
                width={"70px"}
              />
              <h2 class="counter text-secondary">20</h2>
              <h5 class="ttr-title text-primary">Years Experience</h5>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 mb-30">
            <div class="feature-container feature-bx3">
              <Icon
                icon="tabler:award-filled"
                className="mb-4 ttr-title text-primary"
                width={"70px"}
              />

              <h2 class="counter text-secondary">7</h2>
              <h5 class="ttr-title text-primary">Awards</h5>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 mb-30">
            <div class="feature-container feature-bx3">
              <Icon
                icon="fa6-solid:user-doctor"
                className="mb-4 ttr-title text-primary"
                width={"70px"}
              />

              <h2 class="counter text-secondary">25</h2>
              <h5 class="ttr-title text-primary">Doctors</h5>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 mb-30">
            <div class="feature-container feature-bx3">
              <Icon
                icon="raphael:customer"
                className="mb-4 ttr-title text-primary"
                width={"70px"}
              />

              <h2 class="counter text-secondary">800</h2>
              <h5 class="ttr-title text-primary">Satisfied Client</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
