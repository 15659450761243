import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header className="header header-transparent rs-nav shadow bg-white">
      {/* main header */}
      <div className="sticky-header navbar-expand-lg">
        <div className="menu-bar clearfix">
          <div className="container-fluid clearfix">
            {/* website logo */}
            <div className="menu-logo logo-dark">
              <a href="/">
                <h3 className="text-primary">UCONJ</h3>
              </a>
            </div>
            {/* nav toggle button */}
            <button
              className="navbar-toggler collapsed menuicon justify-content-end"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#menuDropdown"
              aria-controls="menuDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span />
              <span />
              <span />
            </button>
            {/* extra nav */}
            <div className="secondary-menu">
              <ul>
                <li className="btn-area">
                  <a href="tel:7326625650" className="btn btn-primary shadow">
                    <Icon
                      icon="mingcute:phone-fill"
                      className="me-2"
                      width="20"
                      height="20"
                    />
                    Call Now{" "}
                  </a>
                </li>
              </ul>
            </div>

            <div
              className="menu-links navbar-collapse collapse justify-content-end"
              id="menuDropdown"
            >
              <div className="menu-logo">
                <a to="/">
                  <h3 className="text-white">UCONJ</h3>
                </a>
              </div>
              <ul className="nav navbar-nav">
                <li className="active">
                  <a href="/">Home</a>
                </li>
                <li>
                  <Link to="/service">Services</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/insurance">Insurance</Link>
                </li>

                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>

              <div className="menu-close">
                <Icon icon="carbon:close-outline" />
              </div>
            </div>
            {/* Navigation Menu END ==== */}
          </div>
        </div>
      </div>
      {/* main header END */}
    </header>
  );
}
