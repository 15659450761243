import logo from "./logo.svg";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Contact from "./Pages/Contact";
import ScrollToTop from "./Components/ScrollToTop";
import ScrollToTopButton from "./Components/ScrollToTopBtn";
import Aboutus from "./Pages/Aboutus";
import Services from "./Pages/Services";
import Insurance from "./Pages/Insurance";

function App() {
  return (
    <div className="App">
      <>
        <div className="page-wraper">
          <Router>
            <Header />
            <ScrollToTop />
            <ScrollToTopButton />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<Aboutus />} />
              <Route path="/service" element={<Services />} />
              <Route path="/insurance" element={<Insurance />} />
            </Routes>
            <Footer />
          </Router>
        </div>
      </>
    </div>
  );
}

export default App;
