import { Icon } from "@iconify/react";
import React from "react";

export default function Footer() {
  return (
    <footer className="footer bg-primary text-white">
      {/* Footer Top */}
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="widget widget_info">
                <div className="footer-logo">
                  <a className="text-white text-hover-dark" href="#">
                    <h3>UCONJ</h3>
                  </a>
                </div>
                <div className="ft-contact">
                  <p>
                    "Here at Urgent Care of NJ we strive to get you in and out
                    while giving you quality care." <br />
                    <b className="text-white">
                      {" "}
                      Dr. Khalid Aziz - Medical Director
                    </b>
                  </p>
                  <div className="contact-bx">
                    <div className="footer-social-link">
                      <ul>
                        <li>
                          <a
                            className="text-white text-hover-dark"
                            target="_blank"
                            href="https://www.facebook.com/"
                          >
                            <img src="images/social/facebook.png" alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            className="text-white text-hover-dark"
                            target="_blank"
                            href="https://twitter.com/"
                          >
                            <img src="images/social/twitter.png" alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            className="text-white text-hover-dark"
                            target="_blank"
                            href="https://www.instagram.com/"
                          >
                            <img src="images/social/instagram.png" alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            className="text-white text-hover-dark"
                            target="_blank"
                            href="https://www.linkedin.com/"
                          >
                            <img src="images/social/linkedin.png" alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-6">
              <div className="widget footer_widget ml-50">
                <h3 className="">Information</h3>
                <ul>
                  <li>
                    <a className="text-white text-hover-dark" href="#about">
                      <span>About Us</span>
                    </a>
                  </li>
                  <li>
                    <a className="text-white text-hover-dark" href="#service">
                      <span>Services</span>
                    </a>
                  </li>
                  <li>
                    <a className="text-white text-hover-dark" href="#reviews">
                      <span>Reviews</span>
                    </a>
                  </li>
                  <li>
                    <a className="text-white text-hover-dark" href="#faq">
                      <span>Faq's</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-6">
              <div className="widget footer_widget">
                <h3 className="">Our Service</h3>
                <ul>
                  <li>
                    <a
                      className="text-white text-hover-dark"
                      href=""
                    >
                      <span>illness & Injury Care</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-white text-hover-dark"
                      href=""
                    >
                      <span>Lab Service</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-white text-hover-dark"
                      href=""
                    >
                      <span>Vaccines</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-white text-hover-dark"
                      href=""
                    >
                      <span>Physical Exam</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-white text-hover-dark"
                      href=""
                    >
                      <span>Pediatics Welcome</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-white text-hover-dark"
                      href=""
                    >
                      <span>Preventative Care</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="widget widget_form">
                <h3 className="">Location</h3>
                <ul className="text-start">
                  <li>
                    <a
                      className="text-white text-hover-dark"
                      href="https://maps.app.goo.gl/RRCjgphbjc2i336g6"
                      target="_blank"
                    >
                      <Icon icon="zondicons:location" /> 2090 Route 27 Edison,
                      NJ 08817
                    </a>
                  </li>
                  <li>
                    <Icon icon="bxs:phone" />{" "}
                    <a
                      className="text-white text-hover-dark"
                      href="tel:732-662-5650"
                    >
                      732-662-5650
                    </a>
                  </li>
                  <li>
                    {" "}
                    <Icon icon="ri:time-line" /> Mon-Fri 8am - 8pm <br />
                    <span className="ms-3 ps-1"></span> Sat-Sun 8am - 6pm
                  </li>
                  <li className="mt-2">
                    <a
                      className="text-white text-hover-dark"
                      href="https://maps.app.goo.gl/rkvwhM6tYj5NTX9P9"
                      target="_blank"
                    >
                      <Icon icon="zondicons:location" />{" "}
                      <small>22690 Woodbridge Ave Edison, NJ 08837</small>
                    </a>
                  </li>
                  <li>
                    <Icon icon="bxs:phone" />{" "}
                    <a
                      className="text-white text-hover-dark"
                      href="tel:732-662-9145"
                    >
                      732-662-9145
                    </a>
                  </li>
                  <li>
                    {" "}
                    <Icon icon="ri:time-line" /> Mon-Fri 9am-5pm <br />
                    <span className="ms-3 ps-1"></span> Sat-Sun Closed
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer bottom */}
      <div className="container">
        <div className="footer-bottom">
          <div className="row">
            <div className="col-12 text-center">
              <p className="copyright-text">Copyright © 2023 uconj</p>
            </div>
          </div>
        </div>
      </div>
      {/* footer-shape */}
    </footer>
  );
}
