import React from 'react'
import Hero from "../Components/Hero";
import WhyUs from "../Components/WhyUs";
import Services from "../Components/Services";
import About from "../Components/About";
import Testimonial from "../Components/Testimonial";
import FAQ from "../Components/FAQ";
import Insurance from "../Components/Insurance";
import Counter from '../Components/Counter';

export default function Home() {
  return (
    <>
       <Hero />
          <WhyUs />
          <About />
          <Insurance />
          <Counter/>
          <Testimonial />
          <Services />
          <FAQ />
    </>
  )
}
