import React from "react";

export default function About() {
  return (
    <section
      id="about"
      className="section-sp1 about-area "
      style={{ backgroundColor: "#fafaf8" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-30">
            <div className="about-thumb-area">
              <img className="about-thumb1 w-75" src="/dr.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6 mb-30">
            <div className="heading-bx">
              <h2 className="title">About Us</h2>
              <p className="text-start">
                Our urgent care clinics strive to care for you like the old
                school primary doctors used to all while utilizing the modern
                diagnostics of today
              </p>
              {/* <h6 className="title-ext text-primary mb-0 pb-0">
         Our Mission
          </h6> */}
            </div>
            {/* <div className="row">
              <div className="col-12 mb-30 mb-sm-20">
                <div className="feature-container feature-bx1 feature1">
                  <div className="icon-md">
                    <span className="icon-cell">
                      <svg
                        enableBackground="new 0 0 512 512"
                        height={85}
                        viewBox="0 0 512 512"
                        width={85}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m509.82 327.343-21.991-27.599c-1.896-2.381-4.775-3.768-7.82-3.768h-7.712l-74.353-93.385c-1.897-2.383-4.777-3.771-7.823-3.771h-22.862v-22.765c0-19.014-15.43-34.483-34.396-34.483h-97.678v-4.552c0-28.428-23.127-51.555-51.555-51.555s-51.555 23.127-51.555 51.555v4.552h-97.678c-18.966 0-34.397 15.47-34.397 34.484v251.241c0 5.523 4.478 10 10 10h22.279c4.628 22.794 24.758 39.999 48.815 39.999s44.186-17.205 48.814-39.999h250.37c4.628 22.794 24.757 39.999 48.814 39.999s44.187-17.205 48.815-39.999h24.093c5.522 0 10-4.477 10-10v-93.722c0-2.264-.769-4.461-2.18-6.232zm-124.52-108.523 61.432 77.156h-79.474v-77.156zm-233.226-81.799c0-17.399 14.155-31.555 31.555-31.555s31.555 14.156 31.555 31.555v4.552h-63.109v-4.552zm-132.074 39.035c0-7.986 6.459-14.483 14.397-14.483h298.464c7.938 0 14.396 6.497 14.396 14.483v241.241h-217.35c-4.628-22.794-24.757-39.999-48.814-39.999s-44.187 17.205-48.815 39.999h-12.278zm61.094 281.24c-16.44 0-29.816-13.458-29.816-29.999s13.376-29.999 29.816-29.999 29.815 13.458 29.815 29.999-13.375 29.999-29.815 29.999zm347.998 0c-16.44 0-29.815-13.458-29.815-29.999s13.375-29.999 29.815-29.999 29.816 13.458 29.816 29.999-13.376 29.999-29.816 29.999zm62.908-39.999h-14.093c-4.628-22.794-24.758-39.999-48.815-39.999s-44.186 17.205-48.814 39.999h-13.02v-101.321h107.932l16.81 21.096z" />
                        <path d="m183.629 66.808c5.522 0 10-4.477 10-10v-12.104c0-5.523-4.478-10-10-10s-10 4.477-10 10v12.104c0 5.523 4.477 10 10 10z" />
                        <path d="m236.764 94.969c1.934 1.829 4.404 2.736 6.871 2.736 2.652 0 5.299-1.048 7.266-3.127l10.626-11.229c3.796-4.011 3.621-10.341-.391-14.137s-10.341-3.621-14.137.391l-10.626 11.229c-3.796 4.012-3.621 10.341.391 14.137z" />
                        <path d="m116.358 94.579c1.967 2.078 4.613 3.126 7.266 3.126 2.467 0 4.938-.907 6.871-2.737 4.012-3.796 4.187-10.125.391-14.137l-10.627-11.229c-3.796-4.011-10.126-4.187-14.137-.39-4.012 3.796-4.187 10.125-.391 14.137z" />
                        <path
                          d="m90.896 216.592h184.372v113.287h-184.372z"
                          fill="#b2f0fb"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h4 className="ttr-title">Ensure proper medical care</h4>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-30 mb-sm-20">
                <div className="feature-container feature-bx1 feature2">
                  <div className="icon-md">
                    <span className="icon-cell">
                      <svg
                        enableBackground="new 0 0 512 512"
                        height={85}
                        viewBox="0 0 512 512"
                        width={85}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m351.524 124.49h-37.907v-37.907h-44.657v37.907h-37.906v44.657h37.906v37.907h44.657v-37.907h37.907z"
                          fill="#a4fcc4"
                        />
                        <path d="m291.289 279.415c73.114 0 132.597-59.482 132.597-132.597s-59.483-132.596-132.597-132.596-132.598 59.482-132.598 132.596 59.484 132.597 132.598 132.597zm0-245.193c62.086 0 112.597 50.511 112.597 112.597s-50.511 112.597-112.597 112.597c-62.087 0-112.598-50.511-112.598-112.597s50.511-112.597 112.598-112.597z" />
                        <path d="m502 267.736c-32.668 0-59.245 26.577-59.245 59.245v13.605h-240.266v-19.048c0-23.625-19.221-42.846-42.846-42.846h-90.398v-17.584c0-32.668-26.577-59.245-59.245-59.245-5.522 0-10 4.478-10 10v275.914c0 5.522 4.478 10 10 10h49.245c5.522 0 10-4.478 10-10v-39.327h373.51v39.327c0 5.522 4.478 10 10 10h49.245c5.522 0 10-4.478 10-10v-210.041c0-5.522-4.478-10-10-10zm-342.356 30.957c12.598 0 22.846 10.249 22.846 22.846v19.048h-113.245v-41.894zm-110.399 179.085h-29.245v-254.623c16.812 4.434 29.245 19.77 29.245 37.954zm20-49.327v-67.864h373.51v67.864zm422.755 49.327h-29.245v-150.797c0-18.185 12.434-33.521 29.245-37.954z" />
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h4 className="ttr-title">Qualified medical staff</h4>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-30 mb-sm-20">
                <div className="feature-container feature-bx1 feature3">
                  <div className="icon-md">
                    <span className="icon-cell">
                      <svg
                        enableBackground="new 0 0 512 512"
                        height={85}
                        viewBox="0 0 512 512"
                        width={85}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m397.886 191.161c-3.545-4.235-9.852-4.797-14.087-1.252-4.235 3.544-4.797 9.851-1.253 14.086 26.684 31.893 41.165 72.339 40.775 113.888-.886 94.681-79.215 172.782-174.608 174.1-48.125.666-93.326-17.479-127.401-51.087-33.708-33.246-52.272-77.526-52.272-124.685 0-59.98 30.361-115.236 81.216-147.809 51.27-32.838 79.187-66.186 93.348-111.507l2.581-8.26 2.58 8.257c9.333 29.869 25.53 55.364 49.516 77.939 4.02 3.786 10.35 3.593 14.136-.428 3.785-4.021 3.594-10.351-.429-14.136-21.713-20.438-35.736-42.471-44.133-69.341l-12.125-38.802c-1.305-4.175-5.171-7.018-9.545-7.018s-8.24 2.843-9.545 7.018l-12.126 38.807c-12.639 40.45-38.072 70.545-85.045 100.63-56.624 36.268-90.429 97.819-90.429 164.65 0 52.553 20.679 101.891 58.228 138.924 37.248 36.736 86.47 56.867 138.888 56.865.941 0 1.891-.006 2.833-.02 51.527-.712 100.087-21.236 136.733-57.792 36.664-36.573 57.12-84.914 57.6-136.118.432-46.301-15.704-91.371-45.436-126.909z" />
                        <path d="m279.576 280.012v-29.712c0-5.523-4.478-10-10-10h-46.783c-5.522 0-10 4.477-10 10v29.712h-29.711c-5.522 0-10 4.477-10 10v46.783c0 5.523 4.478 10 10 10h29.711v29.711c0 5.523 4.478 10 10 10h46.783c5.522 0 10-4.477 10-10v-29.711h29.712c5.522 0 10-4.477 10-10v-46.783c0-5.523-4.478-10-10-10zm19.712 46.783h-29.712c-5.522 0-10 4.477-10 10v29.711h-26.783v-29.711c0-5.523-4.478-10-10-10h-29.711v-26.783h29.711c5.522 0 10-4.477 10-10v-29.712h26.783v29.712c0 5.523 4.478 10 10 10h29.712z" />
                        <path
                          d="m369.497 246.666c51.239-.708 92.983-42.352 93.459-93.223.313-33.486-16.989-62.983-43.266-79.911-21.598-13.914-37.772-29.46-45.4-53.873l-6.143-19.659-6.143 19.661c-7.603 24.331-23.627 39.927-45.19 53.738-26.16 16.756-43.48 45.945-43.48 79.151 0 52.43 43.18 94.848 96.163 94.116z"
                          fill="#ffbdbc"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h4 className="ttr-title">Best patient care</h4>
                  </div>
                </div>
              </div>

            </div>
            <a href="#" className="btn btn-primary shadow">
              Read More
            </a> */}
            <div className="d-flex  align-items">
              <h1 className="text-primary">01.</h1>
              <div className="text-start">
                <h3 className="ms-4 mt-1">Ensure Proper Medical Care</h3>
                <p className="w-100 ms-4">
                  At UCONJ, our foremost commitment is to ensure that you
                  receive the highest quality medical care available. We
                  understand that your health is of paramount importance.
                </p>
              </div>
            </div>
            <div className="d-flex  align-items">
              <h1 className="text-primary">02.</h1>
              <div className="text-start">
                <h3 className="ms-3 mt-1">Qualified Medical Staff</h3>
                <p className="w-100 ms-3">
                  Our medical staff at UCONJ comprises highly qualified and
                  skilled professionals who are at the forefront of healthcare
                  excellence. We take pride in our team.
                </p>
              </div>
            </div>
            <div className="d-flex  align-items">
              <h1 className="text-primary">03.</h1>
              <div className="text-start">
                <h3 className="ms-3 mt-1">Best Patient Care</h3>
                <p className="w-100 ms-3">
                  At UCONJ, delivering the best patient care isn't just a goal;
                  it's our unwavering commitment. We understand that healthcare
                  is not just about treating medical conditions; it's about
                  caring for individuals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className="pt-img1 animate-wave"
        src="images/shap/wave-orange.png"
        alt=""
      />

      <img
        className="pt-img3 animate-rotate"
        src="images/shap/line-circle-blue.png"
        alt=""
      />
    </section>
  );
}
