import { Icon } from "@iconify/react";
import React from "react";

export default function Services() {
  return (
    <section style={{ marginTop: "280px" }}>
      <div className="container">
        <div className="contact-wraper">
          <h1 className="text-center ">Services</h1>
          <div className="col-12 mb-15">
            <div className="container">
              <div className="row ">
                <div className="col-lg-4 col-md-6 col-12 my-3">
                  <div className="feature-container feature-bx2 h-100 feature1">
                    <div className="feature-box-xl mb-30">
                      <span className="icon-cell">
                        <Icon
                          className="text-primary"
                          icon="ic:sharp-personal-injury"
                          width="70"
                          height="70"
                        />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="ttr-title">illness & Injury Care</h3>
                      <p>Walk in urgent care visits, no appointment needed</p>
                      <a href="#" className="btn btn-primary light">
                        View More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 my-3">
                  <div className="feature-container feature-bx2 h-100 feature2">
                    <div className="feature-box-xl mb-20">
                      <span className="icon-cell">
                        <Icon
                          className="text-primary"
                          icon="mingcute:safe-shield-fill"
                          width="70"
                          height="70"
                        />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="ttr-title">Preventative Care</h3>
                      <p>Preventative Care: Prioritizing Health.</p>
                      <a href="#" className="btn btn-primary light">
                        View More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 my-3">
                  <div className="feature-container feature-bx2 h-100 feature3">
                    <div className="feature-box-xl mb-20">
                      <span className="icon-cell">
                        <Icon
                          className="text-primary"
                          icon="entypo:lab-flask"
                          width="70"
                          height="70"
                        />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="ttr-title">Lab Service</h3>
                      <p>Lab tech always on staff and daily pickups</p>
                      <a href="#" className="btn btn-primary light">
                        View More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 my-3">
                  <div className="feature-container feature-bx2 h-100 feature1">
                    <div className="feature-box-xl mb-20">
                      <span className="icon-cell">
                        <Icon
                          className="text-primary"
                          icon="cil:child"
                          width="70"
                          height="70"
                        />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="ttr-title">Pediatrics Welcome</h3>
                      <p>Helping every member of your family</p>
                      <a href="#" className="btn btn-primary light">
                        View More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 my-3">
                  <div className="feature-container feature-bx2 h-100 feature2">
                    <div className="feature-box-xl mb-20">
                      <span className="icon-cell">
                        <Icon
                          className="text-primary"
                          icon="material-symbols:vaccines-rounded"
                          width="70"
                          height="70"
                        />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="ttr-title">Vaccines</h3>
                      <p>Adminstering routine vaccines as well as travel</p>
                      <a href="#" className="btn btn-primary light">
                        View More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 my-3">
                  <div className="feature-container feature-bx2 h-100 feature2">
                    <div className="feature-box-xl mb-20">
                      <span className="icon-cell">
                        <Icon
                          className="text-primary"
                          icon="maki:doctor"
                          width="70"
                          height="70"
                        />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="ttr-title">Physical Exams</h3>
                      <p>We offer annual physicals exams and DOT testing. </p>
                      <a href="#" className="btn btn-primary light">
                        View More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
