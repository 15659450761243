import React from "react";
import { Icon } from "@iconify/react";
export default function WhyUs() {
  return (
    <section
      className="section-area section-sp5 work-area my-4 pt-5"
      style={{
        backgroundImage: "url(images/background/line-bg1.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "100%",
      }}
    >
      <div className="container-sm">
        <div className="heading-bx text-center">
          {/* <h6 className="title-ext text-secondary">Working Process</h6> */}
          <h2 className="title">Why Are Our Services The Best?</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-3  col-md-6 mb-30">
            <div className="work-bx">
              <div className="work-num-bx">01</div>
              <div className="work-content">
                <h5 className="title text-secondary mb-10">
                  Immediate Service
                </h5>
                <p>Willingness to help the patient 7 days a week.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3  col-md-6 mb-30">
            <div className="work-bx">
              <div className="work-num-bx">02</div>
              <div className="work-content">
                <h5 className="title text-secondary mb-10">Laboratory</h5>
                <p>In-house lab services, no need to go elsewhere.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3  col-md-6 mb-30">
            <div className="work-bx">
              <div className="work-num-bx">03</div>
              <div className="work-content">
                <h5 className="title text-secondary mb-10">Modern Equipment</h5>
                <p>Equipment meets global standards.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3  col-md-6 mb-30">
            <div className="work-bx">
              <div className="work-num-bx">04</div>
              <div className="work-content">
                <h5 className="title text-secondary mb-10">Ambulance</h5>
                <p>
                  Cost effective alternative to the ER as well as time saving.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        className="pt-img3 animate3"
        src="images/shap/circle-dots.png"
        alt=""
      />
    </section>
  );
}
