import React from "react";

export default function Aboutus() {
  return (
    <section style={{ marginTop: "280px" }}>
      <div className="container">
        <div className="contact-wraper">
          {" "}
          <h1 className="text-center">About Us</h1>
          <p className="text-center">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quia
            quisquam asperiores illum esse aut provident ipsum maiores hic quam
            ratione dolores consectetur quaerat, doloremque, vero deleniti
            delectus minus. Repudiandae, totam vero? Magnam animi accusamus
            cumque ut, iste nobis, eius aliquam ea unde inventore perspiciatis
            aut sed debitis ipsa expedita? Natus, corporis. Repellendus, sequi
            deleniti voluptas omnis, neque inventore fuga ullam aut dignissimos
            et sit sunt impedit natus cumque, non debitis dolorem quis ipsum
            dolore vel recusandae soluta? Nesciunt, provident vitae? Quam
            praesentium itaque assumenda aliquid repellat voluptatem rerum id
            doloremque sint earum, ipsam dolor ut suscipit perferendis adipisci
            saepe fuga doloribus omnis qui eius et. Sequi reiciendis unde qui
            quibusdam eius repellendus inventore, tenetur odit ipsum doloribus
            quisquam temporibus beatae harum nam maxime in accusamus doloremque.
            Dolorem officia ad ullam impedit maiores, asperiores pariatur
            voluptates unde debitis laboriosam rerum similique omnis fugiat
            tempore dicta, exercitationem sunt! Officia ipsa consectetur
            voluptatem, numquam beatae est voluptate eius nam aspernatur,
            obcaecati quasi mollitia. Neque error possimus obcaecati voluptatum
            nulla dolor aut quis dolores, tenetur saepe optio quia officia
            perferendis sapiente excepturi eaque soluta.
          </p>
          <div className="row mt-5 align-items-center justify-content-center">
            <div className="col-12 text-center my-4">
              <h1 className="text-center">Dr. Khalid Aziz</h1>
              <small className="text-center">Medical Director</small>
            </div>
            <div className="col-md-6 px-5">
              <img className="about-thumb1 w-75" src="/dr.png" alt="" />
            </div>
            <div className="col-md-6">
              <p className="text-start">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa
                quaerat perferendis dolorum blanditiis repellendus nisi hic qui
                sunt aliquid nihil labore ea explicabo, ipsa non omnis at, odio
                ullam laudantium eveniet ratione praesentium? Laborum iure odit
                reiciendis, aut suscipit quod? Iure cupiditate excepturi
                deserunt aspernatur temporibus sequi ab, doloremque adipisci.
                Porro animi cum tenetur quidem molestiae illum, itaque dolore
                vero quae eos eum voluptatum, aperiam saepe temporibus
                voluptatibus fugiat sapiente consequatur ratione deserunt
                libero. Adipisci optio temporibus tempora esse dolorem?
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
