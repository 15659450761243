import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; // Import slick carousel CSS
import "slick-carousel/slick/slick-theme.css"; // Import slick carousel theme CSS
import { Link } from "react-router-dom";

export default function Insurance() {
  const [settings] = useState({
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  });

  const logos = [
    "/logo2.png",
    "/logo3.png",
    "/logo4.png",
    "/logo2.png",
    "/logo5.png",
    "/logo3.png",
    "/logo4.png",
    // Add more logos here
  ];

  const logoContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px", // Set the desired height for the logos
  };

  const logoImageStyle = {
    maxWidth: "100%", // Ensure that logos do not exceed their container's width
    maxHeight: "100px", // Set the maximum height to maintain uniformity
    objectFit: "contain", // Maintain the aspect ratio of logos while fitting them within the container
  };

  return (
    <div
      id="insurance"
      className="container my-5 py-5"
      style={{ textAlign: "center" }}
    >
      <h2 className="" style={{ marginBottom: "70px" }}>
        Insurance Accepted
      </h2>
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} style={logoContainerStyle}>
            <img src={logo} alt="logo" style={logoImageStyle} />
          </div>
        ))}
      </Slider>
      <Link to="/insurance">
        <button className="btn btn-primary rounded-1 mt-5">Read More</button>
      </Link>
    </div>
  );
}
